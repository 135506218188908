import { FC, Fragment } from 'react'
import { Navigate } from 'react-router-dom'
import { useAuth } from '../providers/Auth'
import { useConfig } from '../providers/Config'
import { useLocation } from 'react-router'


const UnauthorizedRoute: FC<{ children: React.ReactNode, redirectTo?: string }> = ({ children, redirectTo = '/', ...rest }) => {
    const { appConfig } = useConfig()
    const { auth: authConfig } = appConfig || {}
    const { redirectTo: _redirectTo = redirectTo } = authConfig || {}
    const { auth } = useAuth()
    let location = useLocation()

    if (!auth?.isAuthenticated || (location.pathname == "/signin")) {
        return (<Fragment>{children}</Fragment>)
    } else {
        return <Navigate to={_redirectTo} replace />
    }
}

export default UnauthorizedRoute;